import { useEffect, useState } from "react";
import comingsoon from "../../../Images/ComingSoon/coming-soon.jpg";
// import Shimmer from "../Common/ShimmerUI/Shimmer";
import Shimmer from "../../Common/ShimmerUI/Shimmer";

import { Blurhash } from "react-blurhash";
const AdvisoryBoard = () => {
    const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);
  
    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }, []);
    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setImgLoading(true);
      };
      img.src = comingsoon;
    }, []);
  return (
    <>
      {loading ? (
        <Shimmer />
      ) : (
        <div className="text-center ">
          {!imgLoading && (
            <Blurhash
              hash="U#OXIFj[^ioft7WCaya}~SfjE3aya{s:a}j?"
              height={500}
              width={500}
              punch={1}
              className="img-"
            />
          )}
          {imgLoading && (
            <img src={comingsoon} alt="Coming Soon" className="img-fluid" />
          )}
        </div>
      )}
    </>
  );
}

export default AdvisoryBoard
