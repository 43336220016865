import React, { useState } from "react";
import logo from "../../Images/Common/ndskills logo.png";
import { Link } from "react-router-dom";
import "../../CSS/main.css";
import { ScrollToTop } from "../utils";
import { Button, Drawer } from "antd";

const navItems = [
  { name: "Home", path: "/" },
  {
    name: "About",
    dropdown: [
      // {
      //   name: "About",
      //   path: "/About",
      // },
      {
        name: "Our Organisation",
        path: "/ourOrganisation",
      },
      { name: "Founder Profile", path: "/founderProfile" },
      { name: "Management Team", path: "/managementTeam" },
      { name: "Advisory Board", path: "/advisoryBoard" },
    ],
  },
  {
    name: "Schools & Programs",
    dropdown: [
      {
        name: "School of Building & Automation",
        path: "/School-of-building-automation",
      },
      { name: "School Of Data Science", path: "/School-of-datascience" },
      { name: "School of Fashion", path: "/School-of-fashion" },
      { name: "School of Health Care", path: "/School-of-healthcare" },
    ],
  },

  { name: "Jobs", path: "/Jobs" },
  { name: "Gallery", path: "/Gallery" },
  { name: "Blog", path: "/Blog" },
  { name: "Contact Us", path: "/Contactus" },
];

const Header = () => {
  const [open, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!open);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const renderNavItems = (isDropdown = false) => {
    return navItems.map((item, index) => {
      if (item.dropdown) {
        return (
          <li key={index} className="nav-item pt-1 dropdown">
            <div
              className="nav-link textColor2 fw-bolder fs-6 dropdown-toggle btn2"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {item.name}
            </div>
            <ul className="dropdown-menu">
              {item.dropdown.map((subItem, subIndex) => (
                <li key={subIndex}>
                  <a
                    href={subItem.path}
                    className="dropdown-item px-4 py-2 dropdownEle"
                    onClick={() => {
                      ScrollToTop();
                      setIsOpen(false);
                    }}
                  >
                    {subItem.name}
                  </a>
                </li>
              ))}
            </ul>
          </li>
        );
      }
      return (
        <li
          key={index}
          className={`nav-item pt-1 ${isDropdown ? "" : "text-black"}`}
        >
          <Link
            to={item.path}
            className="nav-link textColor2 fw-bolder fs-6 btn2"
            onClick={() => {
              ScrollToTop();
              setIsOpen(false);
            }}
          >
            {item.name}
          </Link>
        </li>
      );
    });
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white sticky-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img className="logo-styling " src={logo} alt="ndskills logo" />
          </Link>

          <div
            className={`collapse navbar-collapse ${open ? "hide" : ""}`}
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav mx-auto fw-medium responsive">
              {renderNavItems()}
            </ul>
          </div>

          {/* ************************************** Hamburger Menu ********************************* */}

          <div className="d-lg-none d-xl-none text-center hamburger-icon">
            <Button onClick={toggleNavbar}>
              {open ? (
                <span className="close-icon">✖</span>
              ) : (
                <span className="navbar-toggler-icon"></span>
              )}
            </Button>
            <Drawer
              title="Menu"
              closable={false}
              onClose={onClose}
              open={open}
              placement="right"
              width={250}
              height={50}
              styles={{ body: { padding: "15px" } }}
            >
              <div className="navbar-collapse">
                <ul className="navbar-nav d-flex fw-medium flex-column">
                  {renderNavItems(true)}
                </ul>
              </div>
            </Drawer>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
