export default function Validation(formData) {
  const errors = {};
  const email_pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phone_pattern = /^\d{10}$/;
  if (formData.name === "") {
    errors.name = "Name is required";
  }
  if (formData.email === "") {
    errors.email = "Name is required";
  } else if (!email_pattern.test(formData.email)) {
    errors.email = "Email didn't match";
  }
  if (formData.phone === "") {
    errors.phone = "Phone number is required";
  } else if (!phone_pattern.test(formData.phone)) {
    errors.phone = "Phone number didn't match";
  }
  if (formData.category === "") {
    errors.category = "Please select the category";
  }
  if (formData.subCategory === "") {
    errors.subCategory = "Please select the sub-category";
  }

  return errors;
}
