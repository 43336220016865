import { Helmet } from "react-helmet";
import React from "react";

const MetaTags = ({
  title,
  Description,
  Keywords,
  href,
  OgTitle,
  OgUrl,
  OgDescription,
  OgImg,
  twitterTitle,
  twitterDescription,
  twitterImage,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={Description} />
      <meta name="keywords" content={Keywords} />
      <link rel="canonical" href={href} />
      <meta property="og:title" content={OgTitle} />
      <meta property="og:site_name" content="ND SKILLS" />
      <meta property="og:url" content={OgUrl} />
      <meta property="og:description" content={OgDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={OgImg} />

      {/* ********************* twitter ************* */}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ND SKILLS" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />
    </Helmet>
  );
};

export default MetaTags;
