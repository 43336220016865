import React, { useState, useEffect, useContext } from "react";
import placeholderImage from "../../Images/Common/default.jpg";
import "../../CSS/pop.css";
import { ContextStore } from "../../App";

function ImageComponent({ imageUrl, style, altText }) {
  const { isImgLoaded, setIsImgLoaded } = useContext(ContextStore);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadImage = () => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        // console.log("appeared");
        return setIsImgLoaded(true);
      };
      img.onerror = (error) => setError(error);
    };

    loadImage();
  }, [imageUrl]);

  if (isImgLoaded) {
    return (
      <img loading="lazy" src={imageUrl} alt={altText} className={style} />
    );
  } else {
    return (
      <img
        src={placeholderImage}
        alt={`${altText} + Placeholder`}
        className={style}
      />
    );
  }
}

export default ImageComponent;
