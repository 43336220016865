import React, { useState, useEffect, useRef, useContext } from "react";
import axios from "axios";
import { Container, Row, Col, Form } from "react-bootstrap";
import "../../CSS/contactus.css";
import Validation from "../Validation";
import contactusimg3 from "../../Images/contactus/portrait-woman-customer-service-worker.jpg";
import "../../CSS/main.css";
import Shimmer from "../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../MetaInfo/MetaTags";
import ContactInfoCards from "../Common/contactInfoCards";

const ContactUs = () => {
  const [errors, setErrors] = useState({});
  const [imgLoading, setImgLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    category: "",
    subCategory: "",
    message: "",
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);
  const formRef = useRef(null);
  const imageRef = useRef(null);

  const programmes = {
    "Government Programmes": [
      "Search Engine Optimisation Executive",
      "Solar Panel Installation Technician",
      "Multi Skill Technician",
    ],
    "CSR Programmes": ["Solar PV Installation Techincian"],
    "Paid Programmes": [
      "PG Diploma in digital marketing and analytics",
      "IELTS Online Training Program",
    ],
  };
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = contactusimg3;
  }, []);

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearInterval(time);
  }, []);
  useEffect(() => {
    const adjustImageHeight = () => {
      if (
        (window.innerWidth >= 425 && window.innerWidth <= 768) ||
        (window.innerWidth >= 768 && window.innerWidth <= 992) ||
        window.innerWidth >= 992
      ) {
        if (formRef.current && imageRef.current) {
          imageRef.current.style.height = `${formRef.current.offsetHeight}px`;
        }
      } else {
        if (imageRef.current) {
          imageRef.current.style.height = "auto";
        }
      }
    };

    window.addEventListener("resize", adjustImageHeight);
    adjustImageHeight();

    return () => window.removeEventListener("resize", adjustImageHeight);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    console.log(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
      ...(name === "category" && { subCategory: "" }),
    }));
  };

  const PostData = async (data) => {
    try {
      const response = await axios.post("https://ndskills.in/contactForm.php", {
        name: data.name,
        email: data.email,
        phone: data.phone,
        category: data.category,
        subCategory: data.subCategory,
        message: data.message,
      });
      console.log(response.data);
    } catch (error) {
      console.error("There was an error posting the data!", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = Validation(formData);
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form data submitted:", formData);
      await PostData(formData);
      setFormData({
        name: "",
        email: "",
        phone: "",
        category: "",
        subCategory: "",
        message: "",
      });
      setIsSubmitted(true);
      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    }
  };

  return (
    <>
      <MetaTags
        title={"Best Skill Development Training in Telangana - ND Skills"}
        Description={
          "Join the best skill development trainings in Telangana. Contact ND Skills today to enhance your career with top-notch training and support."
        }
        Keywords={
          "Best Skill Development Courses in Telangana    Contact ND Skills  Free Skill Development training in Hyderabad    Best skill development courses in telangana for students"
        }
        href={"https://ndskills.in/Contactus"}
        OgTitle={"Best Skill Development Training in Telangana - ND Skills"}
        OgUrl={"https://ndskills.in/Contactus"}
        OgDescription={
          "Join the best skill development trainings in Telangana. Contact ND Skills today to enhance your career with top-notch training and support."
        }
        OgImg={
          "https://ndskills.in/static/media/portrait-woman-customer-service-worker.657206d215e9b12e1997.jpg"
        }
        twitterImage={
          "https://ndskills.in/static/media/portrait-woman-customer-service-worker.657206d215e9b12e1997.jpg"
        }
        twitterTitle={
          "Best Skill Development Training in Telangana - ND Skills"
        }
        twitterDescription={
          "Join the best skill development trainings in Telangana. Contact ND Skills today to enhance your career with top-notch training and support."
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <Container className="backGroundColor w-100 rounded-4">
          <Row className="p-3 ps-4 img-contactus">
            <Col
              md={7}
              className="d-flex px-sm-4 bg-body-tertiary rounded flex-column"
              ref={formRef}
            >
              <h1 className="text-center fs-2 py-4 textColor2">
                We would love to hear from you
              </h1>
              <p>
                Whether you have a question about anything, our team is ready to
                answer all your questions.
              </p>
              <Form
                className="d-flex flex-column gap-4"
                onSubmit={handleSubmit}
              >
                <Row>
                  {/* ********************* Name ******************** */}
                  <Col md={6}>
                    <Form.Group controlId="formName">
                      <Form.Label>
                        Name<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <p style={{ color: "red", paddingLeft: "5px" }}>
                          {errors.name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  {/* ********************* Email ******************** */}

                  <Col md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>
                        Email<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p style={{ color: "red", paddingLeft: "5px" }}>
                          {errors.email}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {/* ********************* phone number******************** */}
                  <Col md={6}>
                    <Form.Group controlId="formPhone">
                      <Form.Label>
                        Phone<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Enter your phone number"
                      />
                      {errors.phone && (
                        <p style={{ color: "red", paddingLeft: "5px" }}>
                          {errors.phone}
                        </p>
                      )}
                    </Form.Group>
                  </Col>

                  {/* ********************* category ******************** */}
                  <Col md={6}>
                    <Form.Group controlId="formCategory">
                      <Form.Label className="input-bg">
                        Category<span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Select
                        name="category"
                        value={formData.category}
                        onChange={handleDropdownChange}
                      >
                        <option value="" className="select-option">
                          Select Category
                        </option>
                        <option
                          value="Government Programmes"
                          className="select-option"
                        >
                          Government Programmes
                        </option>
                        <option
                          value="CSR Programmes"
                          className="select-option"
                        >
                          CSR Programmes
                        </option>
                        <option
                          value="Paid Programmes"
                          className="select-option"
                        >
                          Paid Programmes
                        </option>
                      </Form.Select>
                      {errors.category && (
                        <p style={{ color: "red", paddingLeft: "5px" }}>
                          {errors.category}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {/* ********************* sub-category******************** */}

                {formData.category && (
                  <Row>
                    <Col md={12}>
                      <Form.Group controlId="formSubCategory">
                        <Form.Label>
                          Sub Category<span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="subCategory"
                          value={formData.subCategory}
                          onChange={handleDropdownChange}
                        >
                          <option value="" className="select-option">
                            Select Sub Category
                          </option>
                          {programmes[formData.category].map(
                            (programme, index) => (
                              <option
                                key={index}
                                value={programme}
                                className="select-option"
                              >
                                {programme}
                              </option>
                            )
                          )}
                        </Form.Select>
                        {errors.subCategory && (
                          <p style={{ color: "red", paddingLeft: "5px" }}>
                            {errors.subCategory}
                          </p>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {/* ********************* Message ******************** */}

                <Form.Group controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    placeholder="Enter your message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Form.Group>

                {/* ********************* Submit Button  ******************** */}

                <div className="d-flex flex-column gap-2">
                  <button type="submit" className="btn1 py-2 mb-1 rounded-1">
                    Submit
                  </button>
                  {isSubmitted && (
                    <p style={{ color: "green", paddingLeft: "5px" }}>
                      Your form was submitted successfully!
                    </p>
                  )}
                </div>
              </Form>
            </Col>

            {/* ********************* contactus-image  ******************** */}

            <Col
              md={5}
              className="d-flex align-items-center mt-3 mt-md-0 h-100"
            >
              {!imgLoading && (
                <Blurhash
                  hash="[QIEkW%MNGRQI,s;M{t8~pxvxZxa_4WVn$kVj[V]n~xt-;xvW;%LS5NGNFofxut7WBWCxus:jFoz"
                  height={600}
                  punch={1}
                  className="contact-us-image w-100 h-100 rounded-3 object-fit-cover"
                />
              )}
              {imgLoading && (
                <img
                  ref={imageRef}
                  src={contactusimg3}
                  alt="contactus"
                  className="img-fluid contact-us-image rounded-3 w-100"
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col className="bg-body-tertiary m-5 d-flex justify-content-center align-items-center py-4 p-md-5">
              <ContactInfoCards />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default ContactUs;
