import Video1 from "../videos/whatsapp-video-2024-04-25-at-122801-pm-1_6hewo2aW.mp4";
import Video2 from "../videos/Green-Brown-Video-centric-Element-Centric-Video-Background.mp4";
import Video3 from "../videos/NDskills-2.mp4";
import Video4 from "../videos/WhatsApp-Video-2024-04-25-at-12.27.58-PM.mp4";
import Video5 from "../videos/skills-nd.mp4";
import Video6 from "../videos/InShot_20240627_111607213.mp4";
import Video7 from "../videos/InShot_20240627_113844341.mp4";
import Video8 from "../videos/whatsapp-video-2024-04-25-at-122800-pm_TiosjZd5.mp4";
import Video9 from "../videos/InShot_20240627_114222502.mp4";
import Video10 from "../videos/InShot_20240627_114503485.mp4";
import Video11 from "../videos/InShot_20240627_114938323.mp4";
import Video12 from "../videos/InShot_20240627_115411064.mp4";
import Video13 from "../videos/WhatsApp-Video-2024-04-25-at-12.27.59-PM-1.mp4";
import Video14 from "../videos/WhatsApp-Video-2024-04-25-at-12.27.59-PM (1).mp4";
import Video15 from "../videos/WhatsApp-Video-2024-04-25-at-12.27.59-PM-2 (1).mp4";

import pic1 from "../Images/Gallery/mam.jpg";

import pic2 from "../Images/Gallery/sir.jpg";
import pic4 from "../Images/Gallery/pic4.jpg";
import pic5 from "../Images/Gallery/pic5.jpg";
import pic6 from "../Images/Gallery/pic6.jpg";
import pic7 from "../Images/Gallery/pic7.jpg";
import pic8 from "../Images/Gallery/pic8.jpg";
import mou1 from "../Images/Gallery/WhatsApp Image 2024-08-20 at 14.31.43_ff37103f.jpg";
import mou2 from "../Images/Gallery/WhatsApp Image 2024-08-20 at 14.33.55_97cb6f4b.jpg";
import mou3 from "../Images/Gallery/WhatsApp Image 2024-08-20 at 14.35.39_d2f0afb9.jpg";

// training

import pic9 from "../Images/Gallery/9.jpg";
import pic10 from "../Images/Gallery/10.jpg";
import pic11 from "../Images/Gallery/8.jpg";
import pic12 from "../Images/Gallery/7.jpg";
import pic13 from "../Images/Gallery/6.jpg";
import pic14 from "../Images/Gallery/13.jpg";
import pic15 from "../Images/Gallery/ndskill_(1)[1].jpeg";
import pic16 from "../Images/Gallery/ndskills-2[1].jpeg";
import pic17 from "../Images/Gallery/ndskills-4[1].jpeg";
import pic18 from "../Images/Gallery/new-direction-skills[1].jpeg";
import pic19 from "../Images/Gallery/newdirections[1].jpeg";
import pic20 from "../Images/Gallery/pic2.jpg";
import pic21 from "../Images/Gallery/skills-new-direction[1].jpeg";
import pic22 from "../Images/Gallery/Screenshot_2024-01-30-06-35-18-02_a23b203fd3aafc6dcb84e438dda678b6[1].jpg";
import pic23 from "../Images/Gallery/skills-new-directions[1].jpeg";

// assessment

import pic24 from "../Images/Gallery/1.jpg";
import pic25 from "../Images/Gallery/2.jpg";
import pic26 from "../Images/Gallery/3.jpg";
import pic27 from "../Images/Gallery/5.jpg";
import pic28 from "../Images/Gallery/11.jpg";
import pic29 from "../Images/Gallery/pic1.jpg";
import pic30 from "../Images/Gallery/nd-skills.jpeg.jpg";
import pic31 from "../Images/Gallery/ndskills.jpeg.jpg";
import pic32 from "../Images/Gallery/ndskills.jpeg.jpg";
import pic33 from "../Images/Gallery/photo1.jpg";
import pic34 from "../Images/Gallery/Screenshot_2024-01-30-06-40-44-91_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic35 from "../Images/Gallery/Screenshot_2024-01-30-06-42-05-76_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic36 from "../Images/Gallery/Screenshot_2024-01-30-06-38-01-24_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic37 from "../Images/Gallery/Screenshot_2024-01-30-06-37-14-37_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic38 from "../Images/Gallery/Screenshot_2024-01-30-10-25-18-78_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic39 from "../Images/Gallery/Screenshot_2024-01-30-06-40-07-25_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic40 from "../Images/Gallery/Screenshot_2024-01-30-06-37-31-96_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import pic41 from "../Images/Gallery/Screenshot_2024-01-30-06-36-34-50_a23b203fd3aafc6dcb84e438dda678b6.jpg";

// affiliations

import mesclogo from "../Images/Affiliations/mesc.jpg";
import Nasscomlogo from "../Images/Affiliations/Nasscom-logo-svg.svg.png";
import ncsmlogo from "../Images/Affiliations/ncsm.png";
import tssclogo from "../Images/Affiliations/tssc.jpg";
import healthlogo from "../Images/Affiliations/health-removebg-preview.png";
import ESSICIlogo from "../Images/Affiliations/7068ESSICI-Logo-removebg-preview.png";
import tasklogo from "../Images/Affiliations/logo.cf112990.png";
import JNTUlogo from "../Images/Affiliations/JNTU_Hyderabad_logo.png";
// import SSELogo from "../Images/Affiliations/SSElogo_proportional.png";
// import bselogo from "../Images/Affiliations/bselogo_proportional.png";


// Audit Report

import pdf4 from "../Assets/auditreports/NDES B.S 2011-12 F.Y.pdf";
import pdf5 from "../Assets/auditreports/NDES B.S .2012-13 F.Y.pdf";
import pdf6 from "../Assets/auditreports/2013-14 F.Y.pdf";
import pdf7 from "../Assets/auditreports/2014-15 F.Y.pdf";
import pdf8 from "../Assets/auditreports/2015-16 F.Y.pdf";
import pdf9 from "../Assets/auditreports/2016-17 F.Y.pdf";
import pdf10 from "../Assets/auditreports/2017-18 F.Y.pdf";
import pdf11 from "../Assets/auditreports/2018-19 F.Y.pdf";
import pdf12 from "../Assets/auditreports/2019-20 F.Y.pdf";
import pdf13 from "../Assets/auditreports/2020-21 F.Y.pdf";
import pdf14 from "../Assets/auditreports/2021-22 F.Y.pdf";
import pdf15 from "../Assets/auditreports/2022-23 F.Y.pdf";
import pdf16 from "../Assets/auditreports/NDES B.S.2023-24 F.Y.pdf";



// Management Team

import Dummy from "../Images/Gallery/Screenshot_2024-01-30-06-36-34-50_a23b203fd3aafc6dcb84e438dda678b6.jpg";
import CEOImage from "../Images/Management Team/CEO-PIC.jpg";
// import CFOImage from "../Images/Management Team/BALU PHOTO   333.jpg";
// import CFOImage from "../Images/Management Team/balu(CFO).png";
import CFOImage from "../Images/Management Team/balusir(CFO).2.png";





export const videosArray = [
  {
    id: 1,
    url: Video1,
  },
  {
    id: 2,
    url: Video2,
  },
  {
    id: 3,
    url: Video3,
  },
  {
    id: 4,
    url: Video4,
  },
  {
    id: 5,
    url: Video5,
  },
  {
    id: 6,
    url: Video6,
  },
  {
    id: 7,
    url: Video7,
  },
  {
    id: 8,
    url: Video8,
  },
  {
    id: 9,
    url: Video9,
  },
  {
    id: 10,
    url: Video10,
  },
  {
    id: 11,
    url: Video11,
  },
  {
    id: 12,
    url: Video12,
  },
  {
    id: 13,
    url: Video13,
  },
  {
    id: 14,
    url: Video14,
  },
  {
    id: 15,
    url: Video15,
  },
];

// footer underline design

export const UnderLine = () => {
  return (
    <div className="d-flex gap-1 align-items-center">
      <p
        className="border-bottom border-4 border-white rounded-1"
        style={{ width: "20px" }}
      ></p>
      <p
        className="border-bottom border-4 border-white  rounded-1"
        style={{ width: "15px" }}
      ></p>
      <p
        className="border-bottom border-4 border-white  rounded-1"
        style={{ width: "10px" }}
      ></p>
      <p
        className="border-bottom border-4 border-white  rounded-3"
        style={{ width: "5px" }}
      ></p>
    </div>
  );
};

// Scroll Behaviour

export const ScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behaviour: "smooth",
  });
};

// gallery

export const images = [
  {
    id: 1,
    url: mou1,
    alt: "ND Skills representative being welcomed with a bouquet by an official from Task Academy for Skill Development in Telangana during a partnership meeting.",
  },
  {
    id: 2,
    url: mou2,
    alt: "Representative from ND Skills signing an agreement with Task Academy for Skill Development in Telangana during a formal meeting",
  },
  {
    id: 3,
    url: mou3,
    alt: "ND Skills representative and Task Academy for Skill Development in Telangana officials shaking hands after signing a partnership agreement",
  },
  {
    id: 4,
    url: pic1,
    alt: "Man in a gray vest holding a recognition certificate at the Media & Entertainment Skills Council event by Skill India.",
  },
  {
    id: 5,
    url: pic2,
    alt: "Smiling woman in a red saree holding a Skill India recognition certificate at the Media & Entertainment Skills Council event.",
  },

  {
    id: 6,
    url: pic4,
    alt: "Group of professionals participating in a traditional lamp-lighting ceremony at an office event",
  },
  {
    id: 7,
    url: pic5,
    alt: "Man giving an interview to news channels at a media event",
  },
  {
    id: 8,
    url: pic6,
    alt: "Smiling man being interviewed by news reporters with microphones at an event",
  },
  {
    id: 9,
    url: pic7,
    alt: "Business professionals celebrating the grand opening of ND Skills Academy with a ribbon-cutting ceremony.",
  },
  {
    id: 10,
    url: pic8,
    alt: "Professional lighting a ceremonial lamp at the inauguration of ND Skills Academy, surrounded by colleagues and dignitaries.",
  },
];

// training

export const trainingImages = [
  {
    id: 1,
    url: pic9,
    alt: "Students attending a class at ND Skills Academy in Secunderabad, Telangana, India. The image shows a group of young men sitting in a classroom, actively participating in a learning session.",
  },
  {
    id: 2,
    url: pic10,
    alt: "Female students attending a classroom session at ND Skills Academy in Secunderabad, Telangana, India. The instructor, wearing a mask, engages with the attentive class, highlighting the academy's commitment to skill development and education.",
  },
  {
    id: 3,
    url: pic11,
    alt: "ND SKILLS training session in progress with students attentively taking notes at the Brindavan Colony Road center in A.S. Rao Nagar, Secunderabad, Telangana on 8th February 2024",
  },
  {
    id: 4,
    url: pic12,
    alt: "ND Skills Academy students participating in a hands-on solar panel assembly workshop, focusing on practical skills development in renewable energy.",
  },
  {
    id: 5,
    url: pic13,
    alt: "Students attending a classroom session at ND Skills Academy, Laxmipura Housing Society Road, A. S. Rao Nagar, Secunderabad, Telangana, on February 15, 2024",
  },
  {
    id: 6,
    url: pic14,
    alt: "Classroom of ND SKILLS students engaged in a lesson focused on skill development.",
  },
  {
    id: 7,
    url: pic15,
    alt: "ND Skills trainees receiving hands-on training on solar panel installation at a site in Khanapuram, Telangana, dated April 4, 2024.",
  },
  {
    id: 8,
    url: pic16,
    alt: "ND Skills trainees taking a written exam during a classroom session at Brindavan Colony, A.S. Rao Nagar, Secunderabad, Telangana, dated April 3, 2024",
  },
  {
    id: 9,
    url: pic17,
    alt: "ND Skills trainees attending a classroom session in a lecture hall, engaged in learning activities, dated April 2024.",
  },
  {
    id: 10,
    url: pic18,
    alt: "Students in uniforms taking an exam in a workshop-style classroom.",
  },
  {
    id: 11,
    url: pic19,
    alt: "Students attending a practical session in a vocational training workshop.",
  },
  {
    id: 12,
    url: pic20,
    alt: "An educational workshop with students at tables facing electrical training panels.",
  },
  {
    id: 13,
    url: pic21,
    alt: "Students in uniforms writing in notebooks at desks in a classroom with motivational phrases on the wall.",
  },
  {
    id: 14,
    url: pic22,
    alt: "Group of students seated in a classroom with desks, some wearing traditional attire.",
  },
  {
    id: 15,
    url: pic23,
    alt: "Group of people attending an ND Skills workshop in Secunderabad, Telangana.",
  },
];

// assessment

export const assessmentImages = [
  {
    id: 1,
    url: pic24,
    alt: "Group of students and instructors from New Directions Skills Academy standing outside the institute's entrance in A. S. Rao Nagar, Secunderabad, Telangana, April 2024.",
  },
  {
    id: 2,
    url: pic25,
    alt: "Large group of students and instructors from New Directions Skills Academy posing outdoors at Anantha Giri, Telangana, April 2024.",
  },
  {
    id: 3,
    url: pic26,
    alt: "Group of trainees and instructors from New Directions Skills Academy posing indoors with 'Assessor' and 'Trainer' signs at Cherla Gourarm, Telangana, April 2024.",
  },
  {
    id: 4,
    url: pic27,
    alt: "Group of ND Skills trainees standing together outside a storefront in Farooqnagar, Telangana, on April 6, 2024, holding a New Directions Skills banner. The image highlights a training event with participants wearing blue uniforms.",
  },
  {
    id: 5,
    url: pic28,
    alt: "Group of female trainees at New Directions Skills Academy posing in front of the institute entrance. They are dressed in white uniforms with purple scarves, showcasing a training program aimed at skill development",
  },
  {
    id: 6,
    url: pic29,
    alt: "ND Skills training session on April 4, 2024, in Cherla Gourarm, Telangana, featuring trainees with an assessor and trainer.",
  },
  {
    id: 7,
    url: pic30,
    alt: "Group photo of ND Skills participants, trainers, and assessors in Hyderabad, Telangana, taken during an outdoor training session on April 26, 2024.",
  },
  {
    id: 8,
    url: pic31,
    alt: "ND Skills group photo taken in Rajiv Nagar Colony, Bhadrachalam, Andhra Pradesh on April 27, 2024. Participants, trainers, and assessors stand outdoors in front of a blue building during the training session.",
  },
  {
    id: 9,
    url: pic32,
    alt: "ND Skills group photo taken in Rajiv Nagar Colony, Bhadrachalam, Andhra Pradesh on April 27, 2024. Participants, trainers, and assessors stand outdoors in front of a blue building during the training session.",
  },
  {
    id: 10,
    url: pic33,
    alt: "Group photo of female trainees in purple and white uniforms with instructors, taken outside the New Directions and DDU-GKY training center.",
  },
  {
    id: 11,
    url: pic34,
    alt: "Group photo of ND Skills team standing in a garden under clear blue sky.",
  },
  {
    id: 12,
    url: pic35,
    alt: "ND Skills team enjoying a bus ride, smiling and laughing together during a group outing.",
  },
  {
    id: 13,
    url: pic36,
    alt: "ND Skills Telangana team attending an educational entrepreneurship event in Telangana, with participants standing in front of a banner promoting career development and business opportunities",
  },
  {
    id: 14,
    url: pic37,
    alt: "Training session on skill development in Telangana with a group of participants and an instructor in a classroom setting",
  },
  {
    id: 16,
    url: pic38,
    alt: "Group of professionals posing for a team photo, showcasing unity and professional skills.",
  },
  {
    id: 17,
    url: pic39,
    alt: "Group photo of students posing in front of a colonial-style white building with a blue sky backdrop.",
  },
  {
    id: 18,
    url: pic40,
    alt: "A group of attendees posing in front of the International Conclave & Exhibition on Climate Change banner at the 2011 event.",
  },
  {
    id: 19,
    url: pic41,
    alt: "A group of participants posing in front of a banner at the Open Day on Entrepreneurship event in 2008.",
  },
];

export const links = [
  { to: "/", label: "Home" },
  { to: "/About", label: "About" },
  { to: "/Jobs", label: "Jobs" },
  { to: "/Gallery", label: "Gallery" },
  { to: "/Blog", label: "Blog" },
  { to: "/Contactus", label: "Contact Us" },
];
export const SchoolsLinks = [
  { to: "/School-of-building-automation", label: " Building & Automation" },
  { to: "/School-of-datascience", label: "Data Science" },
  { to: "/School-of-fashion", label: "Fashion" },
  { to: "/School-of-healthcare", label: "Health Care" },
];
export const HelpLinks = [
  { to: "/terms", label: "Terms & Conditions" },
  { to: "/governace", label: "Governance" },
  {
    to: "/CancellationRefundPolicy",
    label: "Payment Cancellation & Refund Policy",
  },
];

// affiliations

export const AffiliationsImages = [
  { key: "tssclogo", src: tssclogo, alt: "tssc logo", maxWidth: "150px" },
  { key: "ncsmlogo", src: ncsmlogo, alt: "ncsm logo", maxWidth: "147.29px" },
  { key: "JNTUlogo", src: JNTUlogo, alt: "JNTU logo", maxWidth: "122.75px" },

  { key: "mesclogo", src: mesclogo, alt: "mesc logo", maxWidth: "196.4px" },
  {
    key: "Nasscomlogo",
    src: Nasscomlogo,
    alt: "Nasscom logo",
    marginTop: "60px",
    maxWidth: "247.15px",
  },
  {
    key: "ESSICIlogo",
    src: ESSICIlogo,
    alt: "ESSICI logo",
    maxWidth: "200.39px",
  },
  {
    key: "healthlogo",
    src: healthlogo,
    alt: "health logo",
    maxWidth: "230.99px",
  },
  {
    key: "tasklogo",
    src: tasklogo,
    alt: "task logo",
    maxWidth: "110px",
    marginTop: "10px",
  },
  // {
  //   key: "SSELogo",
  //   src: SSELogo,
  //   alt: "SSE logo",
  //   maxHeight:"110px"
  //   // maxWidth: "110px",
  //   // marginTop: "10px",
  // },
  // {
  //   key: "bselogo",
  //   src: bselogo,
  //   alt: "BSE logo",
  //   maxWidth: "110px",
  //   marginTop: "10px",
  // },
];

export const SeniorManagementTeamImages = [
  {
    id: 1,
    url: CFOImage,
    alt: "Advisor-Image",
    name: "Name",
    role: "Advisor",
  },
  {
    id: 2,
    url: CEOImage,
    alt: "CEOImage",
    name: "Samyuktha Beegala",
    role: "CEO",
  },
  {
    id: 3,
    url: CFOImage,
    alt: "COO-Image",
    name: "Lakshmi Narayana",
    role: "COO",
  },
  {
    id: 4,
    url: CFOImage,
    alt: "CFO-Image",
    name: "VP Balasubramanyam",
    role: "CFO",
  },
  {
    id: 5,
    url: CFOImage,
    alt: "Senior Vice President-Opertaions(Mobilisation &Placement)-Image",
    name: "Robert",
    role: "Senior Vice President-Opertaions(Mobilisation &Placement)",
  },
];


export const OverseasRepresentativesImages = [
  {
    id: 1,
    url: CEOImage,
    alt: "Advisor-Image",
    name: "Name",
    role: "Advisor",
  },
  {
    id: 2,
    url: CEOImage,
    alt: "CEOImage",
    name: "Samyuktha Beegala",
    role: "CEO",
  },


];


export const AuditReports = [
  { name: "Audited Financial Report 2011-2012", pdf: pdf4 },
  { name: "Audited Financial Report 2012-2013", pdf:pdf5 },
  { name: "Audited Financial Report 2013-2014", pdf:pdf6 },
  { name: "Audited Financial Report 2014-2015", pdf:pdf7 },
  { name: "Audited Financial Report 2015-2016", pdf:pdf8 },
  { name: "Audited Financial Report 2016-2017", pdf:pdf9 },
  { name: "Audited Financial Report 2017-2018", pdf:pdf10 },
  { name: "Audited Financial Report 2018-2019", pdf:pdf11 },
  { name: "Audited Financial Report 2019-2020", pdf:pdf12 },
  { name: "Audited Financial Report 2020-2021", pdf:pdf13 },
  { name: "Audited Financial Report 2021-2022", pdf:pdf14 },
  { name: "Audited Financial Report 2022-2023", pdf:pdf15 },
  { name: "Audited Financial Report 2023-2024", pdf:pdf16 },
];