import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CountUp from "react-countup";
import "../../../CSS/About.css";
import "../../../CSS/main.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import OurContribution from "./OurOrgainsation/OurContribution";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import MetaTags from "../../MetaInfo/MetaTags";
import JoinUs from "./FounderProfile/JoinUs";
import FounderCeo from "./OurOrgainsation/FounderCeo";

const About = () => {
  const [loading, setLoading] = useState(true);

  const formatter = (value) => (
    <div>
      <CountUp end={value} separator="," />
      <sup> +</sup>
    </div>
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <MetaTags
        title={"Skill Training for Unemployed Youth in Telangana | DDU-GKY"}
        Description={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood."
        }
        Keywords={
          "Skill Training for Unemployed Youth Telangana DDU-GKY Skill Training ND Skills Unemployed Youth Programs Unemployed Youth Skill Development"
        }
        href={"https://ndskills.in/About"}
        OgTitle={"Skill Training for Unemployed Youth in Telangana | DDU-GKY"}
        OgUrl={"https://ndskills.in/About"}
        OgDescription={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood."
        }
        OgImg={
          "https://ndskills.in/static/media/img-felicitation.8d9a8f3a550473eebc86.jpg"
        }
        twitterImage={
          "https://ndskills.in/static/media/img-felicitation.8d9a8f3a550473eebc86.jpg"
        }
        twitterTitle={
          "Skill Training for Unemployed Youth in Telangana | DDU-GKY"
        }
        twitterDescription={
          "Skill training for unemployed youth in Telangana under DDU-GKY. Boost your career at ND Skill .learn and enhance your skills force livelihood."
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div>
          <div className="container-fluid bg-body-tertiary mt-4 about-div">
            <Container>
              <FounderCeo setLoading={setLoading} />

              {/* *************************** Our Contribution *********************************** */}

              <OurContribution formatter={formatter} />

              {/* *********************************** our organization **************************************************** */}
              <Row>
                <Col xs={12}>
                  <h2 className="textColor">OUR ORGANIZATION</h2>
                  <p className="pb-2 text-justify">
                    New Directions Educational Society (NDES) is a wholly
                    apolitical, non-profit, secular, social development
                    organization. NDES focuses on Skill Development, Training,
                    and Placement Help for both educated and illiterate
                    unemployed youth. We at NDES are convinced that only the
                    proper education and empowerment of the youth can alter the
                    nation’s future. Only employed/economically productive
                    adolescents will become future responsible citizens and
                    contribute to the process of nation building.
                  </p>
                  <p className="pb-2 text-justify">
                    Our strategy seeks to achieve comprehensive socioeconomic
                    development and the economic development of the nation with
                    a focus on the underprivileged by working in the following
                    functional areas: education, empowerment, and gender
                    equality.
                  </p>
                  <p className="pb-2 text-justify">
                    NDES functions as a catalyst by bridging the gap between the
                    industry’s needs and the market’s supply, and we achieve
                    this by building the potential capabilities of these aimless
                    kids and preparing them to demonstrate their talents on par
                    with those of the qualified experts accessible on the
                    market.
                  </p>
                  <p className="pb-2 text-justify">
                    Education for the underprivileged, Skill Development
                    programs for youth, and the Empowerment of deserving youth
                    and women are the focal points of the NDES’s activity. We
                    feel that in the current environment of living in a global
                    village, any effort that is not integrated will be
                    ineffective. We collaborate with numerous stakeholders,
                    whose contributions make this endeavor more effective,
                    responsive, and sustainable.
                  </p>
                  <p className="pb-2 text-justify">
                    We work directly or indirectly with various communities,
                    governments, corporate societies, and civil societies by
                    utilizing the available resources, i.e., human capital,
                    network, finances, etc., in a constructive and collaborative
                    manner so as to achieve the goal of reforming the deprived
                    communities as a whole.
                  </p>
                </Col>

                {/* **************************************** our goals and objectives ******************************************* */}

                <Col xs={12}>
                  <h2 className="textColor">Our Goals & Objectives</h2>
                  <p className="pb-4 text-justify">
                    Our goals and objectives are to provide high-quality
                    education and training to students and unemployed youth,
                    both in India and overseas, equipping them with the skills
                    needed by the industry. We aim to eradicate and prevent
                    child labor, facilitate capacity-building programs for
                    unemployed youth in both rural and urban areas, and foster
                    positive qualitative changes in the lives of marginalized
                    communities.
                  </p>
                </Col>
              </Row>
            </Container>

            {/* ****************************************** join us today ******************************************************** */}
          </div>
          <JoinUs />
        </div>
      )}
    </>
  );
};

export default About;
