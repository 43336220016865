import React, { useEffect, useState } from "react";
import Schoolofdatascience from "../../../Images/Schools&Programmes/young-male-engineer-working-server-room-doing-maintenance.jpg";
import "../../../CSS/main.css";
import { Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";

const SchoolOfDataScience = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    });
  });
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = Schoolofdatascience;
  }, []);
  return (
    <>
      <MetaTags
        title={"Data Science Training in Telangana | ND Skills"}
        Description={
          "Join ND Skills' Data Science Program in Telangana. Get expert-led training in Python, Machine Learning, AI, and more to elevate your data science career."
        }
        Keywords={
          "Data Science Training in Telangana Best data science course in Telangana ND Skills Data Science Program Ai data science course in telangana"
        }
        href={"https://ndskills.in/School-of-datascience"}
        OgTitle={"Data Science Training in Telangana | ND Skills"}
        OgUrl={"https://ndskills.in/School-of-datascience"}
        OgDescription={
          "Join ND Skills' Data Science Program in Telangana. Get expert-led training in Python, Machine Learning, AI, and more to elevate your data science career."
        }
        OgImg={
          "https://ndskills.in/static/media/young-male-engineer-working-server-room-doing-maintenance.5249c6b4c9f2bf97bd9e.jpg"
        }
        twitterImage={
          "https://ndskills.in/static/media/young-male-engineer-working-server-room-doing-maintenance.5249c6b4c9f2bf97bd9e.jpg"
        }
        twitterTitle={"Data Science Training in Telangana | ND Skills"}
        twitterDescription={
          "Join ND Skills' Data Science Program in Telangana. Get expert-led training in Python, Machine Learning, AI, and more to elevate your data science career."
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className=" container-fluid bg-body-tertiary">
          <Row className="py-5 px-sm-3">
            <Col
              md={6}
              className="d-flex justify-content-center align-items-start flex-column"
            >
              <h1 className="textColor fs-3"> School of Data Science</h1>
              <p className="lh-lg">
                In today’s world of data science, having the right skills is
                like having a superpower for understanding and making sense of
                all sorts of data. It’s about being really good at gathering
                information, using cool computer programs to analyse it, and
                figuring out what it all means. Imagine being the detective who
                can solve puzzles hidden in numbers! So, if you’re into coding,
                love playing with data, and enjoy uncovering secrets that
                numbers hold, data science skills are what you need. It’s like
                having your own set of tools to unlock the mysteries hidden in
                the world of information.
              </p>
            </Col>
            <Col md={6}>
              {!imgLoading && (
                <Blurhash
                  hash="]A5ZqAnLH;Y8mjysn$R4bca#HqbIs-VrXTH?bct7V=bGngkDWAV?j[%KozR.e.kDs8kWX9jEjZR5a}o}aKkBMwadt6adkX"
                  height={300}
                  width={550}
                  punch={1}
                  className="img-fluid object-fit-cover rounded"
                />
              )}
              {imgLoading && (
                <img
                  src={Schoolofdatascience}
                  alt="SchoolOfDataScience"
                  style={{ height: "300px", width: "550px" }}
                  className="img-fluid object-fit-cover rounded"
                />
              )}
            </Col>
          </Row>
        </div>
      )}

      <ContactForm
        props={{
          name: "School of Data Science",
          option1: "Digital Marketing & Analytics",
          option2: "Pg Diploma in Artificial Intelligence and Machine Learning",
        }}
      />
    </>
  );
};

export default SchoolOfDataScience;
