import React, { useEffect, useState } from "react";
import Schoolofhealthcare from "../../../Images/Schools&Programmes/image-nadu-college-students-emergency-care-handling-patients-with-secure-blue-dresses.jpeg";
import "../../../CSS/main.css";
import { Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import ClickHere from "./clickHere";
import pdf5 from "../../../Assets/Child-Caretaker.pdf";
import pdf6 from "../../../Assets/Home-Health-Aide.pdf";
import pdf7 from "../../../Assets/QFile_Geriatric-Caregiver.pdf";
import pdf8 from "../../../Assets/MC_General-Duty-Assistant.pdf";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";

const SchoolOfHealthCare = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = Schoolofhealthcare;
  }, []);
  const details = [
    { name: "Childcare Worker", prop: pdf5 },
    { name: "Home Health Aide", prop: pdf6 },
    { name: "Geriatric Care Worker", prop: pdf7 },
    { name: "General Duty Assistant", prop: pdf8 },
  ];

  return (
    <>
      <MetaTags
        title={"Healthcare Training in Telangana | ND Skills"}
        Description={
          "Enroll in ND Skills's Healthcare Training program. Gain expertise from industry professionals and elevate your career in the healthcare sector."
        }
        Keywords={
          "Healthcare Training in Telangana Healthcare Career Advancement ND Skills Healthcare Program Healthcare Sector Skills Development"
        }
        href={"https://ndskills.in/School-of-healthcare"}
        OgTitle={"Healthcare Training in Telangana | ND Skills"}
        OgUrl={"https://ndskills.in/School-of-healthcare"}
        OgDescription={
          "Enroll in ND Skills's Healthcare Training program. Gain expertise from industry professionals and elevate your career in the healthcare sector."
        }
        OgImg={
          "https://ndskills.in/static/media/image-nadu-college-students-emergency-care-handling-patients-with-secure-blue-dresses.1ecee9ff4fd83be2ca2a.jpeg"
        }
        twitterImage={
          "https://ndskills.in/static/media/image-nadu-college-students-emergency-care-handling-patients-with-secure-blue-dresses.1ecee9ff4fd83be2ca2a.jpeg"
        }
        twitterTitle={"Healthcare Training in Telangana | ND Skills"}
        twitterDescription={
          "Enroll in ND Skills's Healthcare Training program. Gain expertise from industry professionals and elevate your career in the healthcare sector."
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className=" container-fluid bg-body-tertiary overflow-hidden">
          <Row className="py-5 px-sm-3 d-flex ">
            <Col md={6} className="img-distance">
              {!imgLoading && (
                <Blurhash
                  hash="UFAd=mpGMdVa?[XTx^s-k=NFx]%gIUtRozXS"
                  height={600}
                  width={450}
                  punch={1}
                  className="img-fluid schools-imgs rounded"
                />
              )}
              {imgLoading && (
                <img
                  src={Schoolofhealthcare}
                  alt="Schoolofhealthcare"
                  className="img-fluid schools-imgs rounded"
                />
              )}
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-start flex-column"
            >
              <h1 className="textColor fs-3 pb-2 textColor">
                School of Health Care
              </h1>
              <p className=" lh-lg">
                Our Health Care training is a transformative journey of
                acquiring knowledge, honing critical skills, and fostering
                compassion. It involves a dynamic blend of theoretical learning
                and hands-on experience, preparing students to navigate the
                complexities of the healthcare landscape with confidence. From
                anatomy and medical procedures to patient care and ethical
                considerations, the training instils a deep sense of
                responsibility and a commitment to making a positive impact on
                individuals’ health and well-being. Through clinical rotations,
                interactive simulations, and collaborative learning, students
                are equipped with the expertise and empathy needed to excel in
                the demanding yet rewarding field of healthcare.
              </p>
            </Col>
          </Row>
        </div>
      )}
      <ClickHere details={details} />
      <ContactForm
        props={{
          name: "School of Healthcare ",
          option1: "General Duty Assistant",
          option2: "Home Health Aide",
          option3: "Geriatric Care Worker",
          option4: "Childcare Worker",
        }}
      />
    </>
  );
};

export default SchoolOfHealthCare;
