import { Container, Row, Col, Card } from "react-bootstrap";
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from "react-icons/fa";
import "../../CSS/contactInfo.css";
import { Link } from "react-router-dom";

const ContactInfoCards = () => (
  <Container>
    <Row className="g-3">
      {/* Address */}
      <Col xs={12} sm={6} md={4} className=" hoverEffect">
        <Link
          to="https://www.google.co.in/maps/@17.4816458,78.5587343,3a,90y,211.13h,92.17t/data=!3m6!1e1!3m4!1sG_1f65Gy9td9HbmS1E-k3A!2e0!7i13312!8i6656?entry=ttu"
          target="_blank"
          className="text-decoration-none text-dark"
        >
          <Card className="contact-card border-0 rounded-lg">
            <Card.Body className="d-flex flex-column text-center justify-content-center">
              <div className="icon-container">
                <FaMapMarkerAlt size={24} className="text-primary" />
              </div>
              <Card.Title>Address</Card.Title>
              <Card.Text className="text-muted small">
                Plot No.59, Road No.1, Dr. A. S. Rao Nagar, ECIL, Secunderabad,
                Hyderabad – 62
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>

      {/* Email */}
      <Col xs={12} sm={6} md={4} className=" hoverEffect">
        <Link
          to="mailto:ram@ndskills.in"
          className="text-decoration-none text-dark"
        >
          <Card className="contact-card border-0  rounded-lg">
            <Card.Body className="d-flex flex-column text-center  justify-content-center">
              <div className="icon-container">
                <FaEnvelope size={24} className="text-success" />
              </div>
              <Card.Title>Email</Card.Title>
              <Card.Text className="text-muted small">
                ram@ndskills.in
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>

      {/* Phone */}

      <Col xs={12} sm={6} md={4} className=" hoverEffect">
        <Link to="tel:+918885711171" className="text-decoration-none text-dark">
          <Card className="contact-card border-0 rounded-lg">
            <Card.Body className="d-flex flex-column text-center  justify-content-center">
              <div className="icon-container">
                <FaPhone size={24} className="text-danger" />
              </div>
              <Card.Title>Phone</Card.Title>
              <Card.Text className="text-muted small">
                +91 888-57-111-71
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </Col>
    </Row>
  </Container>
);

export default ContactInfoCards;
