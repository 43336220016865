import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import founderPic from "../../../../Images/Aboutus-pics/IMG_3225.jpg";
import ceoPic from "../../../../Images/Aboutus-pics/CEO-PIC.jpg";
import award1 from "../../../../Images/Aboutus-pics/women0f2023.jpg";
import award3 from "../../../../Images/Gallery/img-felicitation.jpg";
import { Blurhash } from "react-blurhash";
import "../../../../CSS/About.css";

const FounderCeo = ({ setLoading }) => {
  // const [isExpanded, setIsExpanded] = useState(false);
  // const [isCollapse, setIsCollapse] = useState(false);
  const [imgLoading, setImgLoading] = useState({
    ceoPic: false,
    award1: false,
    award3: false,
    founderPic: false,
  });

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    const images = [ceoPic, award1, award3, founderPic];
    images.forEach((src, index) => {
      const img = new Image();
      img.onload = () => {
        setImgLoading((prev) => ({
          ...prev,
          [Object.keys(imgLoading)[index]]: true,
        }));
      };
      img.src = src;
    });
  }, []);
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // const handleToggle = () => {
  //   setIsExpanded(!isExpanded);
  // };
  // const handleOtherToggle = () => {
  //   setIsCollapse(!isCollapse);
  // };
  return (
    <>
      <Row className="my-5 ">
        <Col
          xs={12}
          // md={6}
          data-aos="fade-left"
          className="d-flex flex-column justify-content-center overflow-hidden align-items-center text-center"
        >
          <h2 className="founder-name text-style">Shri Dasarath Ram Beegala</h2>
          {!imgLoading.founderPic && (
            <Blurhash
              hash="mADIOMW;000Kvz9tx]~BIA$*%fS$5;xtvzMxEMsn$%J9I:j[%1jZ"
              height={500}
              width={500}
              punch={1}
              className="founder-pic-styling"
            />
          )}
          {imgLoading.founderPic && (
            <img
              src={founderPic}
              alt="founder-pic"
              className="founder-pic-styling"
            />
          )}
          <h3 className="fw-bold text-style pt-4">FOUNDER</h3>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <p className="pb-2 text-justify">
            From 1999 to 2012, Shri Dasarath Ram represented over 45 renowned UK
            universities, gaining extensive experience as an education counselor
            and recruiter. During this time, he encountered numerous young
            individuals, both urban and rural, who possessed the necessary
            academic credentials but were hindered by a lack of essential skills
            and communication abilities. This experience inspired the creation
            of NDES, aimed at empowering these unemployed, unskilled youth.
          </p>
          <p className="pb-2 text-justify">
            Shri Dasarath Ram Beegala, a visionary founder and accomplished
            advocate with the High Court of Telangana, is a highly skilled
            social entrepreneur deeply committed to empowering young people and
            fostering positive societal change. With more than two decades of
            experience in the legal profession, he understands the challenges
            facing India’s youth and is determined to address these issues
            through education and skill development.
          </p>
          <p className="pb-2 text-justify">
            Driven by his passion for education and social justice, Shri
            Dasarath Ram Beegala established New Directions Skills to provide
            high-quality training and education to unemployed youth in India.
            His belief in nurturing skill sets has transformed many young
            individuals into employable and successful members of society. Under
            his leadership, New Directions – Skills has become a leading
            institution in employability training, helping thousands of young
            people across India achieve their career aspirations and improve
            their futures.
          </p>

          <p className="pb-2 text-justify">
            Shri Dasarath Ram Beegala’s commitment to social justice and
            equality extends beyond New Directions – Skills. He actively engages
            in advocacy work and various community organizations, serving as an
            advisor to many corporate brands and earning respect in the legal
            and business communities. His dedication and passion for empowering
            young people have significantly impacted many lives, and his vision
            and leadership continue to drive New Directions – Skills forward as
            a force for positive change in India.
          </p>
        </Col>
      </Row>

      {/* ************************************** founder-ceo details ***************************************** */}

      {/* ***************************** Accomplishment **************************/}
    </>
  );
};

export default FounderCeo;
