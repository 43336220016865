


import { Row, Col } from "react-bootstrap";
import React, { useRef } from "react";
import { useInView } from "react-intersection-observer";
import { PiStudentFill } from "react-icons/pi";
import { IoIosPeople } from "react-icons/io";
import { FaPeopleGroup } from "react-icons/fa6";
import { FaTrophy } from "react-icons/fa";
import { Statistic } from "antd";

const StatisticCard = ({
  icon: Icon,
  title,
  value,
  inView,
  formatter,
}) => (
  <Col
    xs={12}
    sm={6}
    lg={3}
    data-aos="fade-up"
    className="d-flex gap-4 align-items-center"
  >
    <Icon size={40} />
    <Statistic title={title} value={inView ? value : 0} formatter={formatter} />
  </Col>
);

const OurContribution = ({ formatter }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
  });
  return (
    <>
      <Row>
        <Col xs={12}>
          <h2
            className="text-center textColor fs-1"
            data-aos="fade-up"
            ref={ref}
          >
            Our Contribution
          </h2>
        </Col>
      </Row>
      <Row className="ms-5">
        <StatisticCard
          icon={FaPeopleGroup}
          title="Students Trained"
          value={1000}
          inView={inView}
          formatter={formatter}
        />
        <StatisticCard
          icon={PiStudentFill}
          title="Batches"
          value={200}
          inView={inView}
          formatter={formatter}
        />
        <StatisticCard
          icon={IoIosPeople}
          title="Trainees"
          value={6000}
          inView={inView}
          formatter={formatter}
        />
        <StatisticCard
          icon={FaTrophy}
          title="Success Stories"
          value={1000}
          inView={inView}
          formatter={formatter}
        />
      </Row>
    </>
  );
};

export default OurContribution
