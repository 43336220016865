import React, { useEffect, useState } from "react";
import Schooloffashion from "../../../Images/Schools&Programmes/create-image-fashion-designing-lab-with-students-working-with-happiness-image.jpeg";
import "../../../CSS/main.css";
import { Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import SchooloffashionPdf from "../../../Assets/MC-Fashion-designer.pdf";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";

const SchoolOfFashion = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  });
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = Schooloffashion;
  }, []);
  const openBrochure = () => {
    window.open(SchooloffashionPdf, "_blank");
  };

  return (
    <>
      <MetaTags
        title={"Fashion Design Training in Telangana | ND Skills"}
        Description={
          "Join ND Skills' Fashion Design program in Telangana. Learn from top designers, master trends, and turn your passion into a career! Enroll today."
        }
        Keywords={
          "Fashion Design Training in Telangana Fashion Design Program Telangana ND Skills Fashion Design Top Fashion Design Courses in Telangana"
        }
        href={"https://ndskills.in/School-of-fashion"}
        OgTitle={"Fashion Design Training in Telangana | ND Skills"}
        OgUrl={"https://ndskills.in/School-of-fashion"}
        OgDescription={
          "Join ND Skills's Fashion Design program in Telangana. Learn from top designers, master trends, and turn your passion into a career! Enroll today."
        }
        OgImg={
          "https://ndskills.in/static/media/create-image-fashion-designing-lab-with-students-working-with-happiness-image.1dd689b4a130e288331d.jpeg"
        }
        twitterImage={
          "https://ndskills.in/static/media/create-image-fashion-designing-lab-with-students-working-with-happiness-image.1dd689b4a130e288331d.jpeg"
        }
        twitterTitle={"Fashion Design Training in Telangana | ND Skills"}
        twitterDescription={
          "Join ND Skills' Fashion Design program in Telangana. Learn from top designers, master trends, and turn your passion into a career! Enroll today."
        }
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className="container-fluid bg-body-tertiary">
          <Row className="py-5 px-sm-3">
            <Col md={6} className="img-distance">
              {!imgLoading && (
                <Blurhash
                  hash="UDEoY,x[ENIpuiKPxuxGyB_2E2RQ9^tRr=$*"
                  height={600}
                  width={450}
                  punch={1}
                  className="img-fluid object-fit-cover rounded"
                />
              )}
              {imgLoading && (
                <img
                  src={Schooloffashion}
                  alt="School of Fashion"
                  className=" img-fluid object-fit-cover rounded"
                />
              )}
            </Col>
            <Col
              md={6}
              className="d-flex flex-column justify-content-center align-items-start"
            >
              <h1 className="textColor fs-3">School of Fashion</h1>
              <p className="py-2 lh-lg">
                Possessing a flair for fashion is akin to having a unique
                ability to conceptualize and bring to life the artistry woven
                into clothing and design. It involves honing creative instincts,
                understanding fabric textures, and mastering the art of garment
                construction. Imagine yourself as the designer who effortlessly
                translates ideas into exquisite pieces, blending aesthetics with
                functionality! If you have a passion for fashion, an eye for
                trends, and a desire to create wearable art, then the School of
                Fashion is your gateway. It’s like having a palette of tools to
                unlock the artistic potential within the realm of fashion,
                allowing you to shape and innovate in the dynamic world of style
                and self-expression.
              </p>
              <button
                className="border border-0 rounded-1 fw-medium btn1 mt-1 px-5 py-3"
                onClick={openBrochure}
              >
                Know more
              </button>
            </Col>
          </Row>
        </div>
      )}
      <ContactForm
        props={{
          name: "School of Fashion",
          option1: "Select your option",
          option2: "Diploma in Textile & Fashion Design",
        }}
      />
    </>
  );
};

export default SchoolOfFashion;
