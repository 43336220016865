import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../CSS/main.css";
import MetaTags from "../MetaInfo/MetaTags";

const Terms = () => {
  return (
    <>
      <MetaTags
        title={" ND Skills Terms and Conditions - User Agreement & Policies"}
        Description={
          "Read ND Skills' Terms and Conditions to clearly understand our user agreement, privacy policies, and service guidelines."
        }
        Keywords={
          "Terms and Conditions  ND Skills Terms  Legal Disclaimer  Website Terms of Use   Privacy Policy"
        }
        href={"https://ndskills.in/terms"}
      />
      <Container>
        <Row>
          <Col>
            <Card className=" bg-body-tertiary p-2 p-md-5 fontStyle">
              <h1 className="py-2 fs-2 textColor">TERMS OF USE:</h1>
              <h4 className="textColor">Disclaimer</h4>
              <p>
                This Portal is designed, developed, and hosted by ND Skills an
                unit of New Directions Education Society Reg:393/210.
              </p>

              <p>
                Links to other websites that have been included on this Portal
                are provided for information only. NDES is not responsible for
                the contents or reliability of linked websites and does not
                necessarily endorse the views expressed within them. We cannot
                guarantee the availability of such linked pages at all times.
              </p>
              <p>
                These terms and conditions shall be governed by and construed in
                accordance with Indian Laws. Any dispute arising under these
                terms and conditions shall be subject to the exclusive
                jurisdiction of the courts of India.
              </p>
              <h3 className="py-2 textColor">Privacy Policy</h3>
              <p>
                ND Skills does not automatically capture any specific personal
                information from you (like name, phone number, or e-mail
                address) that allows us to identify you individually.
              </p>
              <p>
                If the ND Skills Portal requests you to provide personal
                information, you will be informed of the particular purposes for
                which the information is gathered, and adequate security
                measures will be taken to protect your personal information.
              </p>
              <p>
                The ND Skills Portal reserves the right to use any or all
                information submitted by VOs/NGOs/Prospective Beneficiaries for
                purposes such as data compilation, research, and evaluation.
                Regulatory rules of the Government of India would overrule
                options/choices made on this Web-Enabled Interface.
              </p>
              <p>
                We do not sell or share any personally identifiable information
                volunteered on the ND Skills Portal to any third party
                (public/private). Any information provided to this Portal will
                be protected from loss, misuse, unauthorized alteration, or
                destruction.
              </p>
              <p>
                We gather certain information about the User, such as Internet
                protocol (IP) addresses, domain name, browser type, operating
                system, the date and time of the visit, and the pages visited.
                We make no attempt to link these addresses with the identity of
                individuals visiting our site unless an attempt to damage the
                site has been detected.
              </p>
              <h3 className="py-2 textColor">Linking Policy</h3>
              <b className="textColor2 pb-2">
                Links to external websites/portals
              </b>
              <p>
                At many places in this Portal, you shall find links to other
                websites/portals. These links have been placed for your
                convenience.Ndes is not responsible for the contents and
                reliability of the linked websites and does not necessarily
                endorse the views expressed in them. Mere presence of the link
                or its listing on this Portal should not be assumed as an
                endorsement of any kind. We cannot guarantee that these links
                will work all the time and we have no control over the
                availability of linked pages.
              </p>
              <b className="textColor2 pb-2">
                Links to ND Skills Website by other websites
              </b>
              <p>
                We do not object to you linking directly to the information that
                is hosted on this Portal and no prior permission is required for
                the same. However, we would like you to inform us about any
                links provided to this Portal so that you can be informed of any
                changes or updates therein. Also, we do not permit our pages to
                be loaded into frames on your site. The pages belonging to this
                Portal must load into a newly opened browser window of the User.
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Terms;
