import React from "react";
import "../../../../CSS/About.css";
import "../../../../CSS/main.css";
import { ScrollToTop } from "../../../utils";
import { Link } from "react-router-dom";

const JoinUs = () => {
  return (
    <>
      <div className="text-center w-75 d-flex flex-column mx-auto">
        <h2 className="pt-5 pb-3 textColor">JOIN US TODAY!</h2>
        <div className="d-flex flex-column gap-3 align-items-center fw-bolder">
          <h6 className="join-us-styling">
            TOGETHER, WE CAN FORGE A BRIGHTER FUTURE FOR ALL
          </h6>
          <h6 className="join-us-styling">
            At New Directions Educational Society (NDES), we believe in
            transforming potential into proficiency, guiding each individual
            towards success and self-discovery.
          </h6>

          <h6 className="join-us-styling">
            JOIN US IN THIS JOURNEY OF EMPOWERMENT, EDUCATION, AND SUSTAINABLE
            DEVELOPMENT.
          </h6>
          <button className="border border-0 rounded-1 fs-6 fw-medium px-4 py-2 btn1">
            <Link
              to="/Contactus"
              className="text-decoration-none text-white"
              onClick={ScrollToTop}
            >
              Contact Us
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default JoinUs;
